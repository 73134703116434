.App {
    /* height: 90vh; */
    width: 90vw;
    max-width: 768px;
    display: flex;
    flex-direction: column;
    border-style: none;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 2px 2px var(--shadowColor);
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: var(--backGroundColor);
}

.InputsContainer {
    width: 100%;
    /* height: 100%; */
}

.InputOption {
    /* height: 100%; */
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-left: auto;
    margin-right: auto;
}

.InputTitle {
    font-size: 1.5rem;
    text-align: center;
    color: var(--textColor);
}

.TextInput {
    height: 2rem;
    width: 90%;

    background-color: var(--textBoxColor);
    border-style: none;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 2px 2px var(--shadowColor);
    
    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-left: auto;
    margin-right: auto;

    font-size: 1.2rem;
    text-align: center;
    color: var(--textColor);
}

.ColorInput {
    height: 2rem;
    width: 90%;

    background-color: var(--textBoxColor);
    border-style: none;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 2px 2px var(--shadowColor);

    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-left: auto;
    margin-right: auto;

    font-size: 1.2rem;
    text-align: center;
    color: var(--textColor);
}

.NumberInput {
    height: 2rem;
    width: 90%;

    background-color: var(--textBoxColor);
    border-style: none;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 2px 2px var(--shadowColor);

    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-left: auto;
    margin-right: auto;

    font-size: 1.2rem;
    text-align: center;
    color: var(--textColor);
}

.FileInput {
    height: 2rem;
    width: 90%;

    border-style: none;

    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-left: auto;
    margin-right: auto;

    font-size: 1.2rem;
    text-align: center;
    color: var(--textColor);
}

input::file-selector-button {
    color: var(--textColor);
    background-color: var(--textBoxColor);
    border-style: none;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 2px 2px var(--shadowColor);
}

.QrCodeContainer {
    display: flex;
    flex-direction: column;

    /* height: 100%; */
    /* width: 80%; */

    margin: 5%;
    margin-left: auto;
    margin-right: auto;
}

.QrDownloadButton {
    height: 2rem;
    width: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--textBoxColor);
    border-style: none;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 2px 2px var(--shadowColor);
    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-left: auto;
    margin-right: auto;
    color: var(--textColor);
    text-align: center;
}

.QrDownloadButton:hover {
    transform: scale(110%);
}